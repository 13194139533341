<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import {
    GetDataMethods,
} from "@/state/helpers";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            countPage: 1,
            title: "Users",
            items: [
                {
                    text: "Wesal"
                },
                {
                    text: "Deposit",
                }
            ],
            i: 20,
            totalRows: 1,
            topUpData: [],
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "user_id", sortable: false, label: "User ID" },
                { key: "name", sortable: false, label: "Name" },
                { key: "number", sortable: false, label: "Number" },
                { key: "amount", sortable: false, label: "Amount" },
                { key: "note", sortable: false, label: "Note" },
                { key: "bank_id", sortable: false, label: "Bank Id" },
                { key: "confirmed", sortable: false, label: "Confimed" },
                { key: "img", sortable: false, label: "Image" },
                { key: "created_at", sortable: false, label: "Created At" },
                { key: "updated_at", sortable: false, label: "Updated At" },
                { key: "action" }
            ]
        }
    },
    computed: {

        rows() {
            return this.countPage;
        },

    },
    mounted() {
        // Set the initial number of items
        this.GetOrderData()
        this.totalRows = this.topUpData.length;

    },
    watch: {
        currentPage: function () {
            this.GetOrderData(this.currentPage)
        }
    },
    methods: {
        ...GetDataMethods,

        GetOrderData(page) {
            this.getData("get_topUps?page=" + page).then((res) => {
                this.topUpData = res.data.data.data
                this.countPage = res.data.data.CountPages

            })
        },


        /**
         * Search the table data with search input
         */

    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0">
                        <b-tabs nav-class="nav-tabs-custom">
                            <b-tab title-link-class="p-3">
                                <template v-slot:title>
                                    <a class="font-weight-bold active">All Deposit</a>
                                </template>
                                <div class="row mt-4">
                                    <div class="col-sm-12 col-md-6">

                                    </div>
                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                            <label class="d-inline-flex align-items-center">
                                                Search:
                                                <b-form-input v-model="filter" type="search"
                                                    class="form-control form-control-sm ml-2"></b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>
                                <div class="table-responsive">
                                    <b-table class="table-centered" :items="topUpData" :fields="fields" responsive="sm"
                                        :per-page="perPage" :current-page="1" :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                        @filtered="onFiltered">

                                        <template v-slot:cell(img)="data">
                                            <img :src="data.value" alt="Image" width="100%" height="100%">
                                        </template>

                                        <template v-slot:cell(user_.id)="data">
                                            <router-link :to="{ name: 'user', params: { id: data.value } }">{{
                                                data.value }}</router-link>
                                        </template>

                                        <template v-slot:cell(created_at)="data">
                                            <div>{{ data.value | formatDate }}</div>
                                        </template>

                                        <template v-slot:cell(updated_at)="data">
                                            <div>{{ data.value | formatDate }}</div>
                                        </template>

                                        <template v-slot:cell(status)="data">
                                            <div class="badge-soft-success" v-if="data.value == 1"
                                                style="border-radius: 15px; width: 60px;height: 25px;text-align: center;justify-content: center;align-items: center;display: flex">
                                                Success</div>

                                            <div class="badge-soft-danger" v-else
                                                style="border-radius: 15px; width: 60px;height: 25px;text-align: center;justify-content: center;align-items: center;display: flex;">
                                                Faill</div>
                                        </template>

                                        <template v-slot:cell(action)>
                                            <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover
                                                title="Edit">
                                                <i class="mdi mdi-pencil font-size-18"></i>
                                            </a>
                                            <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                                                title="Delete">
                                                <i class="mdi mdi-trash-can font-size-18"></i>
                                            </a>
                                        </template>

                                    </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <!-- <button  v-for="v in i" :key="v" @click="change_page(v)">{{v}}</button> -->
                                                <b-pagination v-model="currentPage" :total-rows="rows"
                                                    :per-page="perPage"></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>